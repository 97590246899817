import React from 'react'
import '../Styles/TextCircle.Style.scss'

const TextCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlLang="en"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      id="text-circle"
    >
      {/* <title>About</title> */}
      <defs>
        <path
          id="textcircle"
          d="M250,400
                       a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
          transform="rotate(12,250,250)"
        />
      </defs>
      <g className="textcircle">
        <text textLength="940">
          <textPath
            xlinkHref="#textcircle"
            aria-label="JOONHO KIM | FRONT-END DEVELOPER"
            textLength="940"
          >
            JOONHO KIM | FRONT-END DEVELOPER |&#160;
          </textPath>
        </text>
      </g>
    </svg>
  )
}

export default TextCircle
