import React, { useEffect, useRef, useContext } from 'react'
import './style.scss'
import { CursorContext } from './CursorManager'

const CustomCursor = () => {
  const { size, type } = useContext(CursorContext)
  const secondaryCursor = useRef(null)
  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  })

  useEffect(() => {
    document.addEventListener('mousemove', (event) => {
      const { clientX, clientY } = event

      // const mouseX = clientX - current.clientWidth / 2
      // const mouseY = clientY - current.clientHeight / 2

      const mouseX = clientX
      const mouseY = clientY

      if (secondaryCursor.current) {
        positionRef.current.mouseX =
          mouseX - secondaryCursor.current.clientWidth / 2
        positionRef.current.mouseY =
          mouseY - secondaryCursor.current.clientHeight / 2

        secondaryCursor.current.style.transform = `translate3d(${positionRef.current.mouseX}px, ${positionRef.current.mouseY}px, 0)`
      }
    })

    return () => {}
  }, [])

  useEffect(() => {
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse)
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current

      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX
        positionRef.current.destinationY = mouseY
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1

        if (
          Math.abs(positionRef.current.distanceX) +
            Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX
          positionRef.current.destinationY = mouseY
        } else {
          positionRef.current.destinationX += distanceX
          positionRef.current.destinationY += distanceY
        }
      }

      if (secondaryCursor && secondaryCursor.current) {
        secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`
      }
      followMouse()
    }
  }, [])

  return (
    <div className={'cursor-wrapper default'}>
      {type === 'next' && (
        <div
          className={`secondary-cursor ${size} ${type}`}
          ref={secondaryCursor}
        >
          <p>{type}</p>
        </div>
      )}
      {type === 'default' && (
        <div
          className={`secondary-cursor ${size} ${type}`}
          ref={secondaryCursor}
        ></div>
      )}
    </div>
  )
}

export default CustomCursor
