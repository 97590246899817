import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Route, Routes, useLocation, BrowserRouter } from 'react-router-dom'
import Main from '../Screens/Main'
import Work from '../Screens/Work'
import About from '../Screens/About'
import AllProject from '../Components/Work/AllProject'
import NotFound from '../Screens/NotFound'
import RouteChangeTracker from '../Components/RouteChangeTracker'
import { useDispatch, useSelector } from 'react-redux'
import { setLocale } from '../store/localeReducer'

const Router = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { locale } = useSelector((state) => state.locale)

  RouteChangeTracker()

  let getLocale = window.navigator.language

  console.log('====================================')
  console.log('locale ?', locale)
  console.log('getLocale ?', getLocale)
  console.log('====================================')
  if (!locale) {
    if (getLocale.includes('en')) {
      getLocale = 'EN'
    } else if (getLocale.includes('JP')) {
      getLocale = 'JP'
    } else if (getLocale === 'ko') {
      getLocale = 'KO'
    } else {
      getLocale = 'EN'
    }

    dispatch(setLocale(getLocale))
  }

  console.log('====================================')
  console.log('new locale ??', locale)
  console.log('====================================')

  const routes = [
    { path: '/', name: 'Home', Component: Main },
    { path: '/about', name: 'About', Component: About },
    { path: '/work', name: 'WorkAll', Component: AllProject },
    { path: '/work/:id', name: 'Work', Component: Work },
    { path: '/about', name: 'Work', Component: About },
    { path: '/*', name: 'Work', Component: NotFound },
  ]

  return (
    <>
      <Routes location={location} key={location.pathname}>
        {routes.map(({ path, Component }, index) => (
          <Route key={index} path={path} element={<Component />} />
        ))}
      </Routes>
    </>
  )
}

export default Router
