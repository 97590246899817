import React from 'react'

const Image = ({ url, opacity, parallaxPos, rotationPosition, scale }) => {
  return (
    <img
      src={url}
      style={{
        opacity,
        transform: `translate3d(${parallaxPos.x}px, ${parallaxPos.y}px, 0px) rotate(${rotationPosition}deg) scale(${scale})`,
      }}
    />
  )
}

export default Image
