import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  locale: '',
}

export const localeSlice = createSlice({
  name: 'currentLocale',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload
    },
  },
})

export const { setLocale } = localeSlice.actions

export default localeSlice.reducer
