import React, {
  useReducer,
  useRef,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import cn from 'classnames'
import animate from './animate'
import Image from './Image'
import Title from './Title'

import './style.scss'
import { CursorContext } from '../CustomCursor/CursorManager'
import { Link } from 'react-router-dom'

const initialState = {
  opacity: 0,
  parallaxPos: { x: 0, y: -20 },
  scale: 0.8,
  rotationPosition: 0,
  active: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'MOUSE/ENTER': {
      return {
        ...state,
        active: true,
      }
    }

    case 'MOUSE/LEAVE': {
      return {
        ...state,
        active: false,
      }
    }

    case 'CHANGE/OPACITY': {
      return {
        ...state,
        opacity: action.payload,
      }
    }

    case 'MOUSE/COODINATES': {
      return {
        ...state,
        parallaxPos: action.payload,
      }
    }

    case 'CHANGE/ROTATION': {
      return {
        ...state,
        rotationPosition: action.payload,
      }
    }

    case 'CHANGE/SCALE': {
      return {
        ...state,
        scale: action.payload,
      }
    }

    default: {
      throw new Error()
    }
  }
}

const baseUrl = process.env.REACT_APP_BACKEND_URL

export default function ProjectItem({ project, itemIndex }) {
  const listItem = useRef(null)
  const [state, dispatch] = useReducer(reducer, initialState)
  const easeMethod = 'easeInOutCubic'
  const { setSize } = useContext(CursorContext)

  const parallax = (event) => {
    const speed = -5
    const x = (window.innerWidth - event.pageX * speed) / 100
    const y = (window.innerHeight - event.pageY * speed) / 100

    // console.log(x, y)

    dispatch({ type: 'MOUSE/COODINATES', payload: { x, y } })
  }

  const handleOpacity = (initialOpacity, newOpacity, duration) => {
    animate({
      fromValue: initialOpacity,
      toValue: newOpacity,
      onUpdate: (newOpacity, callback) => {
        dispatch({ type: 'CHANGE/OPACITY', payload: newOpacity })
        callback()
      },
      onComplete: () => {},
      duration: duration,
      easeMethod: easeMethod,
    })
  }

  const handleScale = (initialScale, newScale, duration) => {
    animate({
      fromValue: initialScale,
      toValue: newScale,
      onUpdate: (newScale, callback) => {
        dispatch({ type: 'CHANGE/SCALE', payload: newScale })
        callback()
      },
      onComplete: () => {},
      duration: duration,
      easeMethod: easeMethod,
    })
  }

  const handleRotation = (currentRotation, duration) => {
    // Random between -15 and 15
    const newRotation = Math.ceil(
      Math.random() * 15 * (Math.round(Math.random()) ? 1 : -1)
    )

    // console.log('newRotation ?', newRotation)

    animate({
      fromValue: currentRotation,
      toValue: newRotation,
      onUpdate: (newRotation, callback) => {
        dispatch({ type: 'CHANGE/ROTATION', payload: newRotation })
        callback()
      },
      onComplete: () => {},
      duration: duration,
      easeMethod: easeMethod,
    })
  }

  const handleMouseEnter = () => {
    if (window.innerWidth > 768) {
      setSize('medium')
      handleOpacity(0.6, 0, 500)
      handleScale(1, initialState.scale, 500)
      handleRotation(state.rotationPosition, 500)
      listItem.current.addEventListener('mousemove', parallax)
      dispatch({ type: 'MOUSE/ENTER' })
    }
  }

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      setSize('small')
      listItem.current.removeEventListener('mousemove', parallax)
      handleOpacity(0, 1, 800)
      handleScale(0.8, 1, 500)
      handleRotation(state.rotationPosition, 500)
      dispatch({ type: 'MOUSE/COODINATES', payload: initialState.parallaxPos })
      dispatch({ type: 'MOUSE/LEAVE' })
    }
  }

  const callbackFunction = (entries) => {
    // console.log('entries ?? ', entries)

    entries.forEach((entry) => {
      entry.target.classList.toggle('show', entry.isIntersecting)
    })
  }

  const options = useMemo(() => {
    return {
      root: null,
      rootMargin: '-50%',
      // threshold: 0.5,
    }
  })

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    const currentTarget = listItem.current

    if (currentTarget) {
      observer.observe(currentTarget)
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget)
      }
    }
  }, [listItem])

  return (
    <div className="project-item-container" ref={listItem}>
      <Link to={`/work/${project.projectId}`}>
        <p className="project-title-mobile">
          {itemIndex + 1 > 9 ? itemIndex + 1 : `0${itemIndex + 1}`}
          <span>{project.service[0]}</span>
        </p>
        <Title
          title={project.titleShort}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      </Link>
      <Image
        url={`${baseUrl}${project.thumbnail}`}
        opacity={state.opacity}
        parallaxPos={state.parallaxPos}
        rotationPosition={state.rotationPosition}
        scale={state.scale}
      />

      <div className={cn('info-block', { 'as-active': state.active })}>
        <p className="info-block-header">
          <span>
            <em className="hash">• </em>
            {itemIndex + 1 > 9 ? itemIndex + 1 : `0${itemIndex + 1}`}
          </span>
        </p>

        {project.service.map((element) => (
          <p key={element}>
            <span>{element}</span>
          </p>
        ))}
      </div>
    </div>
  )
}
