import React, { useState, useContext, useEffect } from 'react'
import { CursorContext } from '../CustomCursor/CursorManager'
import cn from 'classnames'
import './style.scss'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLocale } from '../../store/localeReducer'

const Header = () => {
  const { locale } = useSelector((state) => state.locale)
  const { setSize } = useContext(CursorContext)
  const [opened, setOpen] = useState(false)
  const { pathname } = useLocation()
  const [title, setTitle] = useState('')
  const [isLocaleSetActive, setLocaleSetActive] = useState(false)
  const dispatch = useDispatch()
  const [deviceWidth, setDeviceWidth] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', () => {
      let width = window.innerWidth
      setDeviceWidth(width)
    })
  })
  console.log('====================================')
  console.log('deviceWidth ?', deviceWidth)
  console.log('====================================')

  const handleMouseEnter = () => {
    setSize('medium')
  }

  const handleMouseLeave = () => {
    setSize('small')
  }

  useEffect(() => {
    let name = pathname.replace('/', '')

    if (name) {
      let isDetail = name.includes('work/')
      if (isDetail) {
        setTitle('detail')
      } else {
        setTitle(name)
      }
    } else {
      setTitle('work')
    }
  }, [pathname])

  const toggleLocale = () => {
    setLocaleSetActive((prev) => !prev)
  }

  const setLanguage = (payload) => {
    toggleLocale()
    dispatch(setLocale(payload))
  }

  return (
    <>
      <nav>
        <div
          id="logo"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to="/">
            <img src="/images/assets/logo.png" alt="" />
          </Link>
        </div>
        <div className="overlay-nav">
          <div className="header-container">
            <Link to={title === 'work' ? '/about' : '/'}>
              <h1
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {title === 'work'
                  ? 'about'
                  : title === 'about'
                  ? 'work'
                  : deviceWidth > 639
                  ? 'all work'
                  : ''}
              </h1>
            </Link>
            {title !== 'work' && title !== 'about' && (
              <Link to="/about" style={{ marginLeft: '2rem' }}>
                <h1
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  about
                </h1>
              </Link>
            )}

            <div className="select-language-area" onClick={toggleLocale}>
              <img src="/images/world.png" alt="translate" title="translate" />
              <p>{locale}</p>
            </div>
            <div
              className="language-box"
              style={
                isLocaleSetActive ? { maxHeight: '30rem' } : { maxHeight: 0 }
              }
            >
              <ul>
                <li onClick={() => setLanguage('KO')}>
                  <p
                    style={
                      locale === 'KO' ? { color: '#fff' } : { color: '#000' }
                    }
                  >
                    한국어
                  </p>
                </li>
                <li onClick={() => setLanguage('JP')}>
                  <p
                    style={
                      locale === 'JP' ? { color: '#fff' } : { color: '#000' }
                    }
                  >
                    日本語
                  </p>
                </li>
                <li onClick={() => setLanguage('EN')}>
                  <p
                    style={
                      locale === 'EN' ? { color: '#fff' } : { color: '#000' }
                    }
                  >
                    ENGLISH
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* <div
        className={cn('overlay-burger-icon', { 'as-opened': opened })}
        onClick={() => setOpen(!opened)}
      /> */}

      {/* <div className={cn('overlay-burger-menu', { 'as-opened': opened })}>
        <Link to="/about">
          <h1>About</h1>
        </Link>
        <Link to="/">
          <h1>Work</h1>
        </Link>
      </div> */}
    </>
  )
}

export default Header
