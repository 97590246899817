import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { recent } from '../../data/works'
import { motion } from 'framer-motion'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import { useRef } from 'react'

const AllProject = () => {
  const { pathname } = useLocation()
  const containerRef = useRef()
  const [changed, setChange] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // console.log('changed ?', changed)

  return (
    <>
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          multiplier: 1,
          // ... all available Locomotive Scroll instance options
        }}
        location={pathname}
        containerRef={containerRef}
        // onUpdate={() => console.log('Updated, but not on location change!')} // Will trigger on
      >
        <section className="work-all-container">
          <motion.h3
            initial={{ display: 'none', opacity: 0, y: 20 }}
            animate={{ display: 'block', opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            My Work
          </motion.h3>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="work-all-wrapper"
          >
            {recent.map((item, index) => (
              <Link key={`allWorks-${index}`} to={`/work/${item.id}`}>
                <div>
                  <div className="work-thumb-image">
                    <img src={item.thumbnail} alt="" />
                  </div>
                  <div className="work-thumb-description">
                    <h4>{item.title_en}</h4>
                    <p>Explore</p>
                  </div>
                </div>
              </Link>
            ))}
          </motion.div>
        </section>
      </LocomotiveScrollProvider>
    </>
  )
}

export default AllProject
