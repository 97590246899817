import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { recent } from '../../data/works'
import '../../Styles/Work/Work.Style.scss'
import Header from '../../Components/Header'
import { CursorContext } from '../../Components/CustomCursor/CursorManager'
import './style.scss'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Loading from '../../Components/Loading'

const baseUrl = process.env.REACT_APP_BACKEND_URL

export default function Work() {
  const { locale } = useSelector((state) => state.locale)
  const { pathname } = useLocation()
  const { id } = useParams()
  const navigate = useNavigate()

  const [item, setItem] = useState(null)
  const containerRef = useRef(null)
  const [changed, setChange] = useState(false)
  const [title, setTitle] = useState(false)
  const [nextProjectId, setNextProjectId] = useState('')
  const [nextProjectName, setNextProjectName] = useState('')
  const { setSize, setType } = useContext(CursorContext)
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(true)

  const handleMouseEnter = () => {
    setSize('medium')
    setType('next')
  }

  const handleMouseLeave = () => {
    setSize('small')
    setType('default')
  }

  const getApi = (id) => {
    console.log('id ??', id)
    if (typeof id !== 'undefined') {
      axios
        .get(`${baseUrl}/api/v1/project/${id}`)
        .then((res) => res.data)
        .then((data) => {
          if (data !== null) {
            getDetailApi(data[0].id)
          } else {
            navigate('/404')
          }
        })
        .catch((err) => console.error('id get err', err))
    }
  }

  const getDetailApi = (id) => {
    console.log('detail id?', id)
    axios
      .get(`${baseUrl}/api/v1/project_detail/${id}/${locale}`)
      .then((res) => res.data)
      .then((data) => {
        let getData = data
        setItem(getData)
        setNextProjectId(getData.nextProjectId)
        setNextProjectName(getData.nextProjectName)

        setChange(true)
      })
      .catch((err) => console.error('detail err', err))
  }

  useEffect(() => {
    if (id) {
      getApi(id)
      setSize('small')
      setType('default')

      // let result = recent.filter((data) => data.id === id)[0]

      // if (!result) {
      //   navigate(`/404`)
      //   return
      // }

      // setItem(result)

      // let nextId = result.nextProjectId
      // let nextName = result.nextProjectName
      // setNextProjectId(nextId)
      // setNextProjectName(nextName)

      // setChange(true)

      // let name = pathname.replace('/work/', '')
      // let uppercaseName = name.toUpperCase()
      // let isNameChange = uppercaseName.includes('_')

      // let title = `${name.toUpperCase()} • Joonho Kim`

      // document.querySelector('title').innerText = title

      // if (isNameChange) {
      //   let changeName = uppercaseName.replace('_', '\n')
      //   setTitle(changeName)
      // } else {
      //   setTitle(uppercaseName)
      // }
    }
  }, [id, locale])

  const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

  if (!item) {
    return <Loading />
  } else {
    return (
      <main data-scroll-container ref={containerRef}>
        <div className="content-wrapper">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
            className="title-area"
          >
            <h3>{item.titleEng}</h3>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.8, duration: 0.2 }}
            className="row-wrapper"
          >
            <div className="info-box">
              <small>ROLE / SERVICE</small>
              <div className="divider"></div>
              <p>{item.role}</p>
            </div>
            <div className="info-box">
              <small>CLIENT / COMPANY</small>
              <div className="divider"></div>
              {item.client && item.company ? (
                <p>
                  {item.client} / {item.company}
                </p>
              ) : (
                <p>Personal Project</p>
              )}
            </div>
            <div className="info-box">
              <small>DATE</small>
              <div className="divider"></div>
              <p>{item.period}</p>
            </div>
          </motion.div>

          {!item.isMobile && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="desktop-wrapper"
            >
              <img
                src={`${baseUrl}${item.images[0]}`}
                alt={item.title}
                title={item.title}
              />
            </motion.div>
          )}

          {item.isMobile && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="mobile-wrapper"
            >
              <div className="flex-col">
                <div className="device">
                  <div className="single-image">
                    <div className="overlay overlay-image playpauze">
                      <img
                        src={`${baseUrl}${item.images[0]}`}
                        alt={item.title}
                        title={item.title}
                      />
                    </div>
                  </div>
                  <div className="overlay-device-image">
                    <div
                      className="overlay overlay-device"
                      style={{
                        background: `url("/images/assets/device-${item.device}.png") center center no-repeat`,
                        backgroundSize: 'cover',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="flex-col">
                <div className="device">
                  <div className="single-image">
                    <div className="overlay overlay-image playpauze">
                      {item.isExistVideo && (
                        <video
                          className="overlay"
                          loop
                          muted
                          playsInline
                          autoPlay
                          disablePictureInPicture
                        >
                          <source
                            src={`${baseUrl}${item.videos[0]}`}
                            type="video/mp4"
                          />
                        </video>
                      )}
                      {!item.isExistVideo && (
                        <img
                          src={`${baseUrl}${item.images[1]}`}
                          alt={item.title}
                          title={item.title}
                        />
                      )}
                    </div>
                  </div>
                  <div className="overlay-device-image">
                    <div
                      className="overlay overlay-device"
                      style={{
                        background: `url("/images/assets/device-${
                          item.device !== 'iphone14'
                            ? item.device
                            : 'iphone14-no'
                        }.png") center center no-repeat`,
                        backgroundSize: 'cover',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="flex-col">
                <div className="device">
                  <div className="single-image">
                    <div className="overlay overlay-image playpauze">
                      <img
                        src={
                          item.isExistVideo
                            ? `${baseUrl}${item.images[1]}`
                            : `${baseUrl}${item.images[2]}`
                        }
                        alt={item.title}
                        title={item.title}
                      />
                    </div>
                  </div>
                  <div className="overlay-device-image">
                    <div
                      className="overlay overlay-device"
                      style={{
                        background: `url("/images/assets/device-${item.device}.png") center center no-repeat`,
                        backgroundSize: 'cover',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}

          <div className="detail-content-wrapper">
            {item.links && (
              <div className="link-icon-area">
                {item.links.map((link, index) => (
                  <div
                    key={`link-${index}`}
                    className="link-icon"
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                  >
                    <img
                      src={
                        link.type === 'android'
                          ? '/images/assets/googlePlay.png'
                          : link.type === 'ios'
                          ? '/images/assets/appStore.png'
                          : '/images/assets/web.png'
                      }
                      alt={link.type}
                      title={link.type}
                    />
                    <a href={link.url} target="_blank">
                      {link.type === 'android'
                        ? 'Google Play'
                        : link.type === 'ios'
                        ? 'App Store'
                        : 'Web Page'}
                    </a>
                  </div>
                ))}
              </div>
            )}
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>

          {item.detail &&
            item.detail[0] &&
            item.detail[0].type === 'mobile' && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 1 }}
                className="mobile-wrapper no-bg"
                style={{ margin: '5rem 0' }}
              >
                {item.detail[0].source.map((media, index) => (
                  <div
                    key={`detail-mobile-video-${index}`}
                    className="flex-col"
                  >
                    <div className="device">
                      <div className="single-image">
                        <div className="overlay overlay-image playpauze">
                          {media.type === 'video' && (
                            <video
                              className="overlay"
                              loop
                              muted
                              playsInline
                              autoPlay
                              disablePictureInPicture
                            >
                              <source
                                src={`${baseUrl}${media.path}`}
                                type="video/mp4"
                              />
                            </video>
                          )}
                          {media.type === 'image' && (
                            <img
                              src={`${baseUrl}${media.path}`}
                              alt={item.title}
                              title={item.title}
                            />
                          )}
                        </div>
                      </div>
                      <div className="overlay-device-image">
                        <div
                          className="overlay overlay-device"
                          style={{
                            background: `url("/images/assets/device-${item.device}.png") center center no-repeat`,
                            backgroundSize: 'cover',
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </motion.div>
            )}

          {item.detail &&
            item.detail[0] &&
            item.detail[0].type === 'desktop' && (
              <article>
                {item.detail[0].source.map((media, index) => (
                  <div
                    key={`detail-desktop-video-${index}`}
                    className="desktop-video-wrapper"
                  >
                    <video
                      className="overlay"
                      loop
                      muted
                      playsInline
                      autoPlay
                      disablePictureInPicture
                      style={{
                        padding: '0.8%',
                        backgroundColor: '#2A2B2A',
                        borderRadius: '0.55%',
                      }}
                    >
                      <source
                        src={`${baseUrl}${media.path}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ))}
              </article>
            )}

          {item.detail &&
            item.detail[1] &&
            item.detail[1].type === 'desktop' && (
              <article>
                <div className="col">
                  {item.detail[1].source.map((media, index) => (
                    <img
                      key={`detail-desktop-image-${index}`}
                      src={`${baseUrl}${media.path}`}
                      alt={item.title}
                      title={item.title}
                    />
                  ))}
                </div>
              </article>
            )}

          {item.detail &&
            item.detail[2] &&
            item.detail[2].type === 'desktop' && (
              <article>
                {item.detail[2].source.map((media, index) => (
                  <div
                    key={`detail-desktop-video-${index}`}
                    className="desktop-video-wrapper"
                  >
                    <video
                      className="overlay"
                      loop
                      muted
                      playsInline
                      autoPlay
                      disablePictureInPicture
                    >
                      <source
                        src={`${baseUrl}${media.path}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ))}
              </article>
            )}

          {item.detail &&
            item.detail[0] &&
            item.detail[0].type === 'detail' &&
            item.detail[0].source.map(
              (media, index) =>
                media.type === 'image' && (
                  <img
                    key={`detail-image-${index}`}
                    src={`${baseUrl}${media.path}`}
                  />
                )
            )}

          <article className="detail-content-wrapper">
            <div className="row-wrapper">
              <div className="content-section">
                <h3>Target</h3>
                <p>{item.target}</p>
              </div>

              <div className="content-section">
                <h3>Most Used Functions</h3>
                <ul>
                  {item.functions.map((func, index) => (
                    <li key={`li-${index}`}>・ {func}</li>
                  ))}
                </ul>
              </div>

              <div className="content-section">
                <h3>used Libraries & Packages</h3>
                <ul>
                  {item.skills.map((skill, index) => (
                    <li key={`skills-${index}`}>・ {skill}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="row-wrapper">
              <div className="content-section">
                <h3>Source</h3>
                <div className="source-wrap">
                  <a href={item.sourcePath} target="_blank">
                    <svg
                      viewBox="0 0 16 16"
                      className="w-5 h-5"
                      fill="#fff"
                      aria-hidden="true"
                    >
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
                    </svg>
                    <p style={{ color: '#fff' }}>GitHub</p>
                  </a>
                </div>
              </div>
            </div>
          </article>
        </div>

        <div
          className="next-project-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to={nextProjectId ? `/work/${nextProjectId}` : '/'}>
            <div className="next-project-title-wrapper">
              {nextProjectName && <p>Next Project</p>}
              <h3>{nextProjectName ? nextProjectName : 'All Project'}</h3>
            </div>
          </Link>
        </div>
      </main>
    )
  }
}
