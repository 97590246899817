import React, { useEffect } from 'react'
import './App.scss'
import Router from './Router'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import Header from './Components/Header'
import Footer from './Components/Footer'
import CursorManager from './Components/CustomCursor/CursorManager'
import CustomCursor from './Components/CustomCursor'

import { store } from './store'
import { Provider } from 'react-redux'

function App() {
  // useEffect(() => {
  //   RouteChangeTracker()
  // }, [])

  // const message = locale === 'ko' ? ko : en;

  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <CursorManager>
            <CustomCursor />
            <Header />
            <Router />
            <Footer />
          </CursorManager>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  )
}

export default App
