import React, { useContext, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import gsap from 'gsap'
import TextCircle from '../../Components/TextCircle'
import { CursorContext } from '../../Components/CustomCursor/CursorManager'
import './style.scss'
import ParallaxText from '../../Components/ParallaxText'

const About = () => {
  const { pathname } = useLocation()
  const { setSize } = useContext(CursorContext)
  const profile = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)

    setSize('small')
    // setSize('default')

    let title = 'About • Joonho Kim'

    document.querySelector('title').innerText = title

    // document
    //   .querySelector('meta[property="og:title"]')
    //   .setAttribute('content', title)

    // document
    //   .querySelector('meta[property="og:description"]')
    //   .setAttribute('content', 'About Page')

    // document
    //   .querySelector('meta[property="og:image"]')
    //   .setAttribute('content', '/images/og_image.png')

    if (profile.current) {
      gsap.fromTo(
        profile.current,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          delay: 1,
          duration: 1,
        }
      )
    }
  }, [pathname])

  return (
    <motion.main
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <section className="content-wrapper">
        <article className="info-em-area">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Let's
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Make
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
            Things
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            Better
          </motion.h2>
        </article>

        <article className="info-wrapper">
          <div className="info-image-area">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1.5, duration: 0.5 }}
            >
              <TextCircle />
            </motion.div>
            <img ref={profile} src="/images/myPhoto.png" alt="" />
          </div>
        </article>
      </section>

      {/* <ParallaxText baseVelocity={1.5}>
        Let's Make Something Greate Together
      </ParallaxText>
      <ParallaxText baseVelocity={-1.5}>
        Let's Make Something Greate Together
      </ParallaxText> */}

      <section className="info-detail-wrapper">
        <article className="info-detail-inner">
          <div className="title-area">
            <h3>Skill</h3>
          </div>
          <div className="content-area">
            <ul>
              <li>
                <p>Html</p>
              </li>
              <li>
                <p>Css</p>
              </li>
              <li>
                <p>Sass(SCSS)</p>
              </li>
              <li>
                <p>JavaScript</p>
              </li>
              <li>
                <p>TypeScript</p>
              </li>
              <li>
                <p>React.js</p>
              </li>
              <li>
                <p>Next.js</p>
              </li>
              <li>
                <p>React Native</p>
              </li>
              <li>
                <p>Electron.js</p>
              </li>
              <li>
                <p>Redux</p>
              </li>
              <li>
                <p>Redux Saga</p>
              </li>
              <li>
                <p>Redux Toolkit</p>
              </li>
              <li>
                <p>BootStrap</p>
              </li>
              <li>
                <p>Material UI</p>
              </li>
              <li>
                <p>Styled-Components</p>
              </li>
              <li>
                <p>GitHub</p>
              </li>
              <li>
                <p>Docker</p>
              </li>
              <li>
                <p>AWS EC2</p>
              </li>
              <li>
                <p>AWS S3</p>
              </li>
            </ul>
          </div>
        </article>
        <article className="info-detail-inner">
          <div className="title-area">
            <h3>Tool</h3>
          </div>
          <div className="content-area">
            <ul>
              <li>
                <p>VSCode</p>
              </li>
              <li>
                <p>ITerms2</p>
              </li>
              <li>
                <p>XCode</p>
              </li>
              <li>
                <p>Android Studio</p>
              </li>
              <li>
                <p>PhotoShop</p>
              </li>
              <li>
                <p>XD</p>
              </li>
              <li>
                <p>Slack</p>
              </li>
            </ul>
          </div>
        </article>
        <article className="info-detail-inner">
          <div className="title-area">
            <h3>Used Api</h3>
          </div>
          <div className="content-area">
            <ul>
              <li>
                <p>Kakao Link</p>
              </li>
              <li>
                <p>Kakao Login</p>
              </li>
              <li>
                <p>Naver Login</p>
              </li>
              <li>
                <p>Naver Map</p>
              </li>
              <li>
                <p>FaceBook Login</p>
              </li>
              <li>
                <p>Google Login</p>
              </li>
              <li>
                <p>IAMPORT(payment)</p>
              </li>
            </ul>
          </div>
        </article>
      </section>

      <section
        className="info-detail-wrapper"
        style={{ background: '#131321' }}
      >
        <div className="contact-wrapper">
          <div>
            <p>contact</p>
          </div>
          <div>
            <div>
              <a href="mailto:lippoint.surf0622@gmail.com" target="_blank">
                <img src="/images/assets/email.png" alt="email" title="email" />
                Email
              </a>
            </div>
            <div>
              <a href="tel:01052090471">
                <img
                  src="/images/assets/phone-call.png"
                  alt="email"
                  title="email"
                />
                Mobile
              </a>
            </div>
            <div>
              <a href="https://github.com/Louis-jk" target="_blank">
                <img
                  src="/images/assets/github.png"
                  alt="email"
                  title="email"
                />
                Github
              </a>
            </div>
            <div>
              <a
                href="https://stackoverflow.com/users/15318755/louis"
                target="_blank"
              >
                <img
                  src="/images/assets/stack-overflow.png"
                  alt="email"
                  title="email"
                />
                Stackoverflow
              </a>
            </div>
          </div>
        </div>
      </section>
    </motion.main>
  )
}

export default About
