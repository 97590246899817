import React from 'react'
import './style.scss'

export default function Loading() {
  return (
    <div className="loading-container">
      <p>Loading</p>
    </div>
  )
}
