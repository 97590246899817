import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGa from 'react-ga'

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID

const RouteChangeTracker = () => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGa.initialize(TRACKING_ID)
    }
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGa.pageview(location.pathname + location.search)
    }
  }, [initialized, location])

  useEffect(() => {
    ReactGa.initialize(TRACKING_ID)
    ReactGa.pageview(location.pathname + location.search)
  }, [location])
}

export default RouteChangeTracker
