import React, { useState, createContext } from 'react'

export const CursorContext = createContext({
  size: 'small',
  setSize: () => {},
})

const CursorManager = (props) => {
  const [size, setSize] = useState('small')
  const [type, setType] = useState('default')

  return (
    <CursorContext.Provider value={{ size, setSize, type, setType }}>
      {props.children}
    </CursorContext.Provider>
  )
}

export default CursorManager
