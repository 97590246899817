import React from 'react'
import { useLocation } from 'react-router-dom'
import './style.scss'

const Footer = () => {
  const { pathname } = useLocation()

  if (pathname === '/') {
    return <div className="footer-fixed-copyright">2022 © Joonho Kim</div>
  } else {
    return (
      <footer>
        <div className="footer-copyright">
          <p>2022 © Joonho Kim</p>
        </div>
      </footer>
    )
  }
}

export default Footer
