import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  ReactNode,
  CSSProperties,
  useMemo,
  useEffect,
} from 'react'
import { motion } from 'framer-motion'
import { recent } from '../../data/works'
import CustomCursor from '../CustomCursor'
import CursorManager from '../CustomCursor/CursorManager'
import Footer from '../Footer'
import Header from '../Header'
import ProjectItem from '../ProjectItem'
import './style.scss'

const Home = ({ projects }) => {
  const InfiniteScrollLoop = ({
    surroundingBackup = 4,
    outerStyle,
    innerStyle,
    children,
  }) => {
    const contentRef = useRef(null)
    const scrollRef = useRef(null)
    const [height, setHeight] = useState(0)

    const backupHeight = height * surroundingBackup

    const handleScroll = React.useCallback(() => {
      if (scrollRef.current) {
        const scroll = scrollRef.current.scrollTop
        if (scroll < backupHeight || scroll >= backupHeight + height) {
          scrollRef.current.scrollTop = backupHeight + (scroll % height)
        }
      }
    }, [height])

    useLayoutEffect(() => {
      if (contentRef.current) {
        setHeight(contentRef.current.offsetHeight)
        scrollRef.current.scrollTop = backupHeight
      }
    })

    return (
      <div className="infinite-scroll-loop-outer" style={outerStyle}>
        <div
          className="infinite-scroll-loop-inner"
          ref={scrollRef}
          style={{
            height,
            ...innerStyle,
          }}
          onScroll={handleScroll}
        >
          {Array(surroundingBackup)
            .fill()
            .map((_, index) => (
              <div key={`surroundingBackup01-${index}`}>{children}</div>
            ))}
          <div ref={contentRef}>{children}</div>
          {Array(surroundingBackup)
            .fill()
            .map((_, index) => (
              <div key={`surroundingBackup02-${index}`}>{children}</div>
            ))}
        </div>
      </div>
    )
  }

  return (
    projects && (
      <>
        <motion.div
          initial={{ display: 'flex' }}
          animate={{ display: 'none' }}
          transition={{ delay: 2.8, duration: 1 }}
          className="loading"
        >
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.2 }}
          >
            Make
          </motion.h1>
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.2 }}
          >
            Things
          </motion.h1>
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.9, duration: 0.2 }}
          >
            Better
          </motion.h1>
          <motion.div
            initial={{ clipPath: 'circle(0% at 50% 50%)' }}
            animate={{ clipPath: 'circle(100% at 50% 50%)' }}
            transition={{ delay: 2, duration: 1 }}
            className="loading-inner"
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.5, duration: 1 }}
        >
          <section className="main-container" id="main-container">
            <InfiniteScrollLoop>
              {projects.map((project, index) => (
                <ProjectItem
                  key={`project-${index}`}
                  project={project}
                  itemIndex={index}
                />
              ))}
            </InfiniteScrollLoop>
          </section>
        </motion.div>
        <div className="scroll-info">
          <span>scroll</span>
        </div>
      </>
    )
  )
}

export default Home
