import React from 'react'

export default function NotFound() {
  return (
    <div className="notfound-wrapper">
      <h2>Oops!</h2>
      <h2>Not Found</h2>
    </div>
  )
}
