import React, { useRef, Suspense, useEffect, useState } from 'react'
import '../Styles/Main.Style.scss'
import Home from '../Components/Home'
import { motion } from 'framer-motion'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setLocale } from '../store/localeReducer'

const baseUrl = process.env.REACT_APP_BACKEND_URL

const Main = () => {
  const dispatch = useDispatch()
  const [projects, setProjects] = useState([])
  const baseUrl = 'https://joonhokim.dev'

  useEffect(() => {
    document.querySelector('title').innerText = 'Joonho Kim'

    document
      .querySelector('meta[property="og:title"]')
      .setAttribute('content', 'Joonho Kim FrontEnd Developer')

    document
      .querySelector('meta[property="og:description"]')
      .setAttribute('content', 'Joonho Kim Portfolio')

    document
      .querySelector('meta[property="og:image"]')
      .setAttribute('content', '/images/og_image.png')
  }, [])

  const getApi = () => {
    axios
      .get(`${baseUrl}/api/v1/projects`)
      .then((res) => res.data)
      .then((data) => setProjects(data))
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    getApi()

    return () => {
      getApi()
    }
  }, [])

  return (
    <main>
      <Home projects={projects} />
    </main>
  )
}

export default Main
