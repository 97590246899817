export const recent = [
  {
    id: 'dongnaebook_pos',
    nextProjectId: 'dongnaebook_owner',
    nextProjectName: 'Dongnae\nbook\nFor Owners',
    title: '동네북(점주용) 포스',
    title_en: 'Dongnae\nbook\npos system',
    title_short: 'DongnaeBook\npos',
    links: [
      {
        type: 'web',
        url: 'https://dongnaebook.app/',
      },
    ],
    development: 'desktop',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2022.07 - 2022.10',
    skills: [
      'electron.js',
      'react.js',
      'redux',
      'redux-saga',
      'node-serialport',
      'escpos',
      'escpos-serialport',
      'material-ui',
      'axios',
      'redux',
      'FCM(Firebase Cloud Messaging)',
    ],
    assets: '/images/desktop/dongnaebook-pos.png',
    thumbnail: '/images/desktop/dongnaebook_pos/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/desktop/dongnaebook_pos/main_screen.png',
      '/images/desktop/dongnaebook_pos/screen_capture02.png',
    ],
    role: 'Front-End Development',
    service: [
      'Desktop',
      'Point Of Sale',
      'Electron & React',
      'Front-end Development',
    ],
    os: 'Windows / Mac',
    content: `데스크탑 어플리케이션.
      동네북 서비스 점주용 매장 포스기 어플리케이션.
      기존 오늘의주문 포스앱 베이스로 추가 기능을 더하여 제작한 프로그램입니다.
      electron.js와 react.js를 사용하여 제작하였고 node.js기반 SerialPort 모듈을 사용하여 ESCPOS 프린터의 드라이버 설치 없이 프린트 출력이 가능하도록 제작한 프로그램입니다.      
      `,
    sourcePath: 'https://github.com/Louis-jk/dongnaebook-owner-pos',
    issue: `SerialPort 통신 및 출력시 thermal printer 출력에서 발생되는 레이아웃 스타일 부분에서 각종 escpos 프린터의 명령어 및 사용 패키지의 이슈사항으로 이슈사항이 있었음.`,
    functions: [
      '로그인 기능',
      '알림 설정 기능 : 알림음, 알림 횟수',
      '실시간 주문 리스트 및 관리 기능',
      '주문 접수',
      '매장 설정 기능',
      '카테고리 및 메뉴 설정 기능',
      '매장 휴무일 관련 설정 기능',
      '매장 선택 및 해당 또는 전체 매장 적용 기능',
      '리뷰 관리 및 공지 기능',
      '공지사항 리스트',
      '프린트 자동출력 기능',
      '프레임 축소, 확대, 닫기 커스텀',
      '그 외 설정 기능',
    ],
    isMobile: false,
    client: '주식회사 어스닉(USNIQ)',
    company: 'Dmonster',
    location: '한국 부산',
  },
  {
    id: 'dongnaebook_owner',
    nextProjectId: 'moli_listener',
    nextProjectName: 'Moli\nFor Listener',
    title: '동네북 점주',
    title_en: 'Dongnae\nbook\nFor Owners',
    title_short: 'DongnaeBook Mobile',
    links: [
      {
        type: 'android',
        url: 'https://play.google.com/store/apps/details?id=com.dmonster.dongnaebookowner',
      },
      {
        type: 'ios',
        url: 'https://apps.apple.com/kr/app/%EB%8F%99%EB%84%A4%EB%B6%81-%EC%A0%90%EC%A3%BC%EC%9A%A9/id1641656034',
      },
      {
        type: 'web',
        url: 'https://dongnaebook.app/',
      },
    ],
    development: 'mobile',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2022.07 - 2022.10',
    skills: [
      'React Native',
      '@react-navigation',
      'Redux',
      'Redux Saga',
      'FCM(Firebase Cloud Messaging)',
      'jwt-encode',
      'jwt-decode',
      'Axios',
      'react-native-version-check',
      'Reanimated',
    ],
    assets: '/images/mobile/dongnaebook_owner.png',
    thumbnail: '/images/mobile/dongnaebook_owner/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/video.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/mobile/dongnaebook_owner/screen_capture01.png',
      '/images/mobile/dongnaebook_owner/screen_capture02.png',
    ],
    role: 'Front-End Development',
    service: ['Mobile', 'React Native', 'Front-end Development'],
    os: 'iOS, Android',
    content: `동네북 점주용 스마트폰 어플리케이션.
      React Native를 사용하여 제작하였습니다.
      FCM(Firebase Cloud Messaging)을 사용하여 사용자의 주문시 알림 처리 및 실시간 주문 현황 리스트 출력 및 알림.
      그 외 메뉴관리, 정산관리, 매장관리, 리뷰관리 등 관리자용 어플리케이션 입니다.
      `,
    sourcePath: 'https://github.com/Louis-jk/dongnaebook_owner',
    issue: `애플 앱 스토어 및 구글 플레이에 올라간 최종 앱의 버전과 현재 사용자 휴대폰에 설치된 버전을 비교하여 업데이트를 유도하는 알림창을 구현해야함으로 react-native-version-check 패키지를 사용하여 해결하였습니다.`,
    functions: [
      '로그인 기능',
      '알림 설정 기능 : 알림음, 알림 횟수',
      '실시간 주문 확인 리스트',
      '주문 접수',
      '매장 설정 기능',
      '카테고리 및 메뉴 설정 기능',
      '매장 휴무일 관련 설정 기능',
      '매장 선택 및 해당 또는 전체 매장 적용 기능',
      '그 외 설정 기능',
    ],
    isMobile: true,
    isExistVideo: true,
    device: 'iphone14',
    client: '주식회사 어스닉(USNIQ)',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'detail',
        source: [
          {
            type: 'image',
            path: '/images/mobile/dongnaebook_owner/detail/detail01.png',
          },
        ],
      },
    ],
  },
  {
    id: 'moli_listener',
    nextProjectId: 'mozaiq',
    nextProjectName: 'Mozaiq',
    title: '모리(moli)-청취자 플레이어',
    title_en: 'Moli\nFor Listener',
    title_short: 'Moli',
    links: [
      {
        type: 'web',
        url: 'http://changeall2019.mycafe24.com/service/pc/',
      },
    ],
    development: 'desktop',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2022.06 - 2022.07',
    skills: [
      'Electron.js',
      'React.js',
      'Styled-components',
      'AgoraSDK',
      'Redux-toolkit',
      'Axios',
      'Typescript',
    ],
    assets: '/images/desktop/moli.png',
    thumbnail: '/images/desktop/moli_listener/thumbnail02.png',
    videos: ['/videos/moli/play.mp4'],
    images: ['/images/desktop/moli_listener/main_screen.png'],
    role: 'Front-End Development',
    service: [
      'Desktop',
      'Electron & React',
      'Agora SDK',
      'Front-end Development',
    ],
    os: 'Windows / Mac',
    content: `실시간 통역 어플 모리(moli)의 데스크탑용 어플리케이션.
      electron.js를 이용하여 Mac, Windows용 데스크탑 어플리케이션을 제작하였고, 컨벤션의 각종 섹션 별로 해당 자료를 다운 받을 수 있고 해당 섹션의 통역 기능을 들을 수 있는 어플리케이션입니다.
      AgoraSDK를 이용하여 VoiceCall 기능으로 웹 또는 모바일에서 통역사가 송신하는 리스트를 출력, 듣고 싶은 통역 부분은 청취자 모드로 접속 및 청취 가능한 기능을 가지고 있습니다.

      `,
    sourcePath: 'https://github.com/Louis-jk/moli_listener_electron',
    issue: `기존 사이즈보다 더 작은 사이즈로 리사이징 가능한 지에 대한 요청이 있어 확인한 결과 electron.js에서 제공되는 리사이징의 경우 Windows는 Frameless로 리사이징이 불가함으로 Windows와 Mac를 별도로 처리하여 Windows에서는 가로값은 고정으로 단 세로값은 Maximun과 Minimun 사이에서 리사이징 가능, Mac에서는 Maxium가로값 및 Minimun가로값, Maximun세로값 및 Minimun세로값 안에서 리사이징이 가능하도록 처리하였습니다.`,
    functions: [
      '프레임 리사이징 기능',
      '통역사 실시간 접속 확인 기능',
      '청취자 모드 접속 및 중지 기능',
      '관련자료 다운로드 기능',
      '일반 로그인 및 SNS 로그인 기능',
      '언어 설정 기능',
      '그 외 설정 기능',
    ],
    isMobile: false,
    client: '체인지올',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'desktop',
        source: [
          {
            type: 'video',
            path: '/videos/desktop/moli_listener/detail/detail02.mp4',
          },
          {
            type: 'video',
            path: '/videos/desktop/moli_listener/detail/detail03.mp4',
          },
        ],
      },
      {
        type: 'desktop',
        source: [
          {
            type: 'image',
            path: '/images/desktop/moli_listener/detail/detail01.png',
          },
          {
            type: 'image',
            path: '/images/desktop/moli_listener/detail/detail02.png',
          },
        ],
      },
    ],
  },
  {
    id: 'mozaiq',
    nextProjectId: 'vegastong',
    nextProjectName: 'Vegastong',
    title: '모자이크(Mozaiq)',
    title_en: 'Mozaiq',
    title_short: 'Mozaiq',
    links: [
      {
        type: 'android',
        url: 'https://play.google.com/store/apps/details?id=com.mozaiq',
      },
      {
        type: 'ios',
        url: 'https://apps.apple.com/kr/app/%EB%AA%A8%EC%9E%90%EC%9D%B4%ED%81%AC-mozaiq/id1623557112',
      },
    ],
    development: 'mobile',
    project: '개인',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2022.06 - 2022.06',
    skills: [
      'React Native',
      'Deeplink',
      'iamport-react-native',
      'styled-components',
      'redux-toolkit',
    ],
    assets: '/images/mobile/mozaiq.png',
    thumbnail: '/images/mobile/mozaiq/thumbnail02.png',
    videos: ['/videos/mozaiq/screen_record.mp4'],
    images: [
      '/images/mobile/mozaiq/screen_capture01.png',
      '/images/mobile/mozaiq/screen_capture02.png',
    ],
    role: 'Front-End Development',
    service: ['Mobile', 'React Native', 'Front-end Development'],
    os: 'iOS, Android',
    content: `펜션 실시간 예약 모바일 어플리케이션.
    React Native의 WebView 버전으로 제작한 프로젝트입니다. 
    제작된 웹페이지의 WebView 통신 및 DeepLink 연동(문자 및 카카오 알림톡 등 공유), SNS 로그인 연동, 결제 모듈 연동, 상담 봇 연동, 이미지 알림 및 앱스토어 등록을 담당하였습니다.
      `,
    sourcePath: 'https://github.com/Louis-jk/mozaiq',
    issue: ``,
    functions: [
      'SNS로그인 기능',
      '실시간 펜션 예약 기능',
      '결제 기능',
      '회원 정보 수정 기능',
      '나이 제한 기능',
      '상담 봇 기능',
      '이미지 알림 기능',
    ],
    isMobile: true,
    isExistVideo: true,
    device: 'iphone13',
    client: 'MZQ Company',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'detail',
        source: [
          {
            type: 'image',
            path: '/images/mobile/mozaiq/detail/detail01.png',
          },
        ],
      },
    ],
  },
  {
    id: 'vegastong',
    nextProjectId: 'diningcode',
    nextProjectName: 'Dining Code',
    title: '베가스통(Vegastong)',
    title_en: 'Vegastong',
    title_short: 'Vegastong',
    development: 'mobile',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2022.06 - 2022.06',
    skills: ['React Native', 'Redux', 'Axios', 'WebView'],
    assets: '/images/mobile/vegastong/thumbnail02.png',
    thumbnail: '/images/mobile/vegastong/thumbnail02.png',
    videos: [
      '/videos/vegastong/video.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/mobile/vegastong/screen_capture01.png',
      '/images/mobile/vegastong/screen_capture02.png',
      '/images/mobile/vegastong/screen_capture03.png',
    ],
    role: 'Front-End Development',
    service: ['Mobile', 'React Native', 'Front-end Development'],
    os: 'iOS, Android',
    content: `미국 라스베가스(LasVegas)의 정보 공유 및 커뮤니티 모바일 어플리케이션.
      관리자 발신용 새소식 리스트 및 상세페이지와 라스베가스 호텔, 골프, 맛집 등의 지도 공유, 사용자용 커뮤니티 기능이 있는 어플리케이션입니다.
      React Native를 이용한 개발 부분을 담당하였습니다.
      `,
    sourcePath: 'https://github.com/Louis-jk/vegastong',
    issue: ``,
    functions: [
      '일반/SNS 로그인 기능',
      '사용자 커뮤니티 기능',
      '카테고리별 검색 기능',
      '관리자 새소식 리스트',
      '관리자 광고 지역 지도 리스트',
      '광고 배너',
    ],
    isMobile: true,
    isExistVideo: false,
    device: 'samsungs22',
    client: 'Dmonster 미국지사',
    company: 'Dmonster',
    location: '한국 부산',
  },
  {
    id: 'diningcode',
    nextProjectId: 'todaysorder_pos',
    nextProjectName: `Today's\nOrders\npos system`,
    title: '다이닝코드 리뉴얼',
    title_en: 'Dining Code',
    title_short: 'Dining Code',
    links: [
      {
        type: 'web',
        url: 'https://www.diningcode.com/',
      },
    ],
    development: 'web',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2021.11 - 2022.05',
    skills: [
      'next.js',
      'styled-components',
      'axios',
      'canvas',
      'crypto-js',
      'd3.js',
      'chart.js',
      'react-spring',
      'react-player',
      'aws-ec2',
      'aws-s3',
    ],
    assets: '/images/web/diningcode.png',
    thumbnail: '/images/web/diningcode/thumbnail02.png',
    videos: ['/videos/diningcode/diningcode_web02.mp4'],
    images: ['/images/web/diningcode/main_screen.png'],
    role: 'Front-End Development',
    service: ['Web', 'Next & React', 'AWS', 'Front-end Development'],
    os: 'Web Browser\n- Safari\n- Chrome\n- IE11+\n- Edge\n- Naver Whale',
    content: `다이닝 코드 리뉴얼 프로젝트.
    웹 프론트엔드 개발 부분을 담당하여 진행하였으며 반응형 페이지로 로그인 관련 페이지, 회원 가입 및 수정 페이지, 메인 정보 출력 페이지, 검색 결과 페이지, 식당 프로파일 페이지, 갤러리 페이지, 지도 검색 페이지, 마이페이지, 리뷰 작성 기능, 체크인 관련 기능, 공유 기능 등을 제작하였고 소식, 실시간 리뷰, 매거진 페이지 등의 수정과 반응형 처리 등을 진행하였습니다.
    리뉴얼 작업본은 아직 반영이 되어 있지 않습니다.
      `,
    sourcePath: 'https://github.com/newDiningcode/diningcode',
    issue: ``,
    functions: [
      '회원 가입 및 수정 기능',
      '로그인 관련 기능',
      '마이페이지 기능',
      '텍스트/지도 검색 기능',
      '리뷰/체크인 작성 기능',
      'SNS 및 MMS 등 공유 기능',
      '갤러리 뷰어 기능',
      '댓글 기능',
      '그 외 여러 기능',
    ],
    isMobile: false,
    isExistVideo: false,
    device: 'iphone13',
    client: '다이닝코드',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'mobile',
        source: [
          {
            type: 'video',
            path: '/videos/diningcode/detail/detail_mobile01.mp4',
          },
          {
            type: 'video',
            path: '/videos/diningcode/detail/detail_mobile02.mp4',
          },
          {
            type: 'video',
            path: '/videos/diningcode/detail/detail_mobile03.mp4',
          },
        ],
      },
      {
        type: 'desktop',
        source: [
          {
            type: 'image',
            path: '/images/web/diningcode/detail/detail01.png',
          },
          {
            type: 'image',
            path: '/images/web/diningcode/detail/detail02.png',
          },
          {
            type: 'image',
            path: '/images/web/diningcode/detail/detail03.png',
          },
          {
            type: 'image',
            path: '/images/web/diningcode/detail/detail04.png',
          },
          {
            type: 'image',
            path: '/images/web/diningcode/detail/detail05.png',
          },
          {
            type: 'image',
            path: '/images/web/diningcode/detail/detail06.png',
          },
        ],
      },
      {
        type: 'desktop',
        source: [
          {
            type: 'video',
            path: '/videos/diningcode/detail/main_image_change.mp4',
          },
          {
            type: 'video',
            path: '/videos/diningcode/detail/map_record.mp4',
          },
        ],
      },
    ],
  },
  {
    id: 'todaysorder_pos',
    nextProjectId: 'todaysorder_owner',
    nextProjectName: `Today's\nOrders\nFor Owners`,
    title: '오늘의주문(점주용) 포스',
    title_en: `Today's\nOrders\npos system`,
    title_short: `Today's Orders\nPos`,
    development: 'desktop',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2021.10 - 2022.02',
    skills: [
      'electron.js',
      'react.js',
      'redux',
      'node-serialport',
      'escpos',
      'escpos-serialport',
      'material-ui',
      'axios',
      'redux',
      'FCM(Firebase Cloud Messaging)',
    ],
    assets: '/images/desktop/ohjoo-pos-full.png',
    thumbnail: '/images/desktop/todaysorder_pos/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/desktop/todaysorder_pos/main_screen.png',
      '/images/desktop/todaysorder_pos/screen_capture02.png',
    ],
    role: 'Front-End Development',
    service: [
      'Desktop',
      'Design',
      'Front-end Development',
      'Electron & React',
      'Point Of Sale',
    ],
    os: 'Windows / Mac',
    content: `오늘의 주문 점주용 매장 관리 데스크탑 어플리케이션.
    오늘의 주문 점주용 모바일 앱의 기능에 영수증 출력 기능과 알림음 소리 강도조절 등의 기능을 추가한 포스기 전용 어플리케이션.
    electron.js와 react.js을 사용하여 제작하였고 windows7 타겟으로 빌드 및 테스팅까지 진행한 프로젝트입니다. 
      `,
    sourcePath: 'https://github.com/Louis-jk/ohjoo-pos-app',
    issue: ``,
    functions: [
      '로그인 기능',
      '알림 설정 기능 : 알림음, 알림 횟수',
      '실시간 주문 리스트 및 관리 기능',
      '주문 접수',
      '매장 설정 기능',
      '카테고리 및 메뉴 설정 기능',
      '매장 휴무일 관련 설정 기능',
      '매장 선택 및 해당 또는 전체 매장 적용 기능',
      '리뷰 관리 및 공지 기능',
      '공지사항 리스트',
      '프린트 자동출력 기능',
      '프레임 축소, 확대, 닫기 커스텀',
      '그 외 설정 기능',
    ],
    isMobile: false,
    client: '아람기획',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'desktop',
        source: [
          {
            type: 'video',
            path: '/videos/desktop/todaysorder_pos/detail/detail01.mp4',
          },
          {
            type: 'video',
            path: '/videos/desktop/todaysorder_pos/detail/detail02.mp4',
          },
        ],
      },
      {
        type: 'desktop',
        source: [
          {
            type: 'image',
            path: '/images/desktop/todaysorder_pos/detail/detail01.png',
          },
          {
            type: 'image',
            path: '/images/desktop/todaysorder_pos/detail/detail02.png',
          },
          {
            type: 'image',
            path: '/images/desktop/todaysorder_pos/detail/detail03.png',
          },
          {
            type: 'image',
            path: '/images/desktop/todaysorder_pos/detail/detail04.png',
          },
          {
            type: 'image',
            path: '/images/desktop/todaysorder_pos/detail/detail05.png',
          },
          {
            type: 'image',
            path: '/images/desktop/todaysorder_pos/detail/detail06.png',
          },
          {
            type: 'image',
            path: '/images/desktop/todaysorder_pos/detail/detail07.gif',
          },
          {
            type: 'image',
            path: '/images/desktop/todaysorder_pos/detail/detail08.gif',
          },
        ],
      },
    ],
  },
  {
    id: 'todaysorder_owner',
    nextProjectId: 'idraw',
    nextProjectName: 'Idraw',
    title: '오늘의 주문 점주',
    title_en: `Today's\nOrders\nFor Owners`,
    title_short: `Today's Orders\nMobile`,
    development: 'mobile',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2021.09 - 2022.02',
    skills: [
      'React Native',
      'Redux',
      'Axios',
      'FCM(Firebase Cloud Messaging)',
      'Reanimated',
    ],
    assets: '/images/mobile/ohjoo_owner.png',
    thumbnail: '/images/mobile/todaysorder_owner/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/mobile/todaysorder_owner/screen_capture01.png',
      '/images/mobile/todaysorder_owner/screen_capture02.png',
      '/images/mobile/todaysorder_owner/screen_capture03.png',
    ],
    role: 'Front-End Development',
    service: ['Mobile', 'React Native', 'Front-end Development'],
    os: 'iOS, Android',
    content: `오늘의 주문 점주용 모바일 어플리케이션.
    주문 관리 및 매장 관리, 리뷰 관리 등의 점주 전용 모바일 어플리케이션.
    React Native로 제작하였으며 사용자용 모바일 어플리케이션의 animation 부분등을 추가로 담당하였습니다.
      `,
    sourcePath: 'https://github.com/Louis-jk/ohjoo_store',
    issue: ``,
    functions: [
      '로그인 기능',
      '알림 설정 기능 : 알림음, 알림 횟수',
      '실시간 주문 리스트 및 관리 기능',
      '주문 접수',
      '매장 설정 기능',
      '카테고리 및 메뉴 설정 기능',
      '매장 휴무일 관련 설정 기능',
      '매장 선택 및 해당 또는 전체 매장 적용 기능',
      '리뷰 관리 및 공지 기능',
      '공지사항 리스트',
      '프린트 자동출력 기능',
      '그 외 설정 기능',
    ],
    isMobile: true,
    isExistVideo: false,
    device: 'samsungs22',
    client: '아람기획',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'detail',
        source: [
          {
            type: 'image',
            path: '/images/mobile/todaysorder_owner/detail/detail01.png',
          },
        ],
      },
    ],
  },
  {
    id: 'idraw',
    nextProjectId: 'paperworkshop_partners',
    nextProjectName: 'paper\nworkshop\nFor Partners',
    title: '아이드로우(idraw)',
    title_en: 'Idraw',
    title_short: 'Idraw',
    links: [
      {
        type: 'android',
        url: 'https://play.google.com/store/apps/details?id=com.com_idraw_dmonster',
      },
      {
        type: 'ios',
        url: 'https://apps.apple.com/us/app/idraw-%EC%95%84%EC%9D%B4%EB%93%9C%EB%A1%9C%EC%9A%B0/id1587099684',
      },
      {
        type: 'web',
        url: 'https://idraw.kr/index.php',
      },
    ],
    development: 'mobile',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2021.07 - 2021.07',
    skills: ['React Native', 'Redux', 'Axios'],
    assets: '/images/mobile/idraw.png',
    thumbnail: '/images/mobile/idraw/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/mobile/idraw/screen_capture01.png',
      '/images/mobile/idraw/screen_capture02.png',
      '/images/mobile/idraw/screen_capture03.png',
    ],
    role: 'Front-End Development',
    service: ['Mobile', 'React Native', 'Front-end Development'],
    os: 'iOS, Android',
    content: `미술 교육 관련 강의 예약 모바일 어플리케이션.
    API 통신 기능 전까지의 전 페이지 Front 개발 업무를 담당하였으며 로그인, 메인, 큐레이션 리스트, 큐레이터 리스트, 큐레이터 상세, 수강자 정보, 드로어 메뉴, 교육 리스트, 전시 리스트, 교육 예약 등의 비주얼 부분을 개발하였습니다.
      `,
    sourcePath: 'https://github.com/Louis-jk/idraw',
    issue: ``,
    functions: [
      '로그인 기능',
      '교육 리스트 출력',
      '큐레이터 리스트 출력',
      '미술 관련 코디 강좌 예약 기능',
      '관련 코디 프로필 열람 기능',
      '각종 전시회 리스트 출력',
      '공지사항 리스트 출력',
    ],
    isMobile: true,
    isExistVideo: false,
    device: 'samsungs22',
    client: '(주)심률',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'detail',
        source: [
          {
            type: 'image',
            path: '/images/mobile/idraw/detail/detail01.png',
          },
        ],
      },
    ],
  },
  {
    id: 'paperworkshop_partners',
    nextProjectId: 'paperworkshop_customer',
    nextProjectName: 'paper\nworkshop\nFor Customers',
    title: '페이퍼공작소-파트너스',
    title_en: 'paper\nworkshop\nFor Partners',
    title_short: 'paper\nworkshop\nFor Partners',
    links: [
      {
        type: 'android',
        url: 'https://play.google.com/store/apps/details?id=com.dmonster.paperworkshoppartners',
      },
    ],
    development: 'mobile',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2021.02 - 2022.06',
    skills: [
      'React Native',
      'Redux',
      'Axios',
      'FCM(Firebase Cloud Messaging)',
      'react-native-image-crop-picker',
      'formik',
      'yup',
      'react-native-fs',
    ],
    assets: '/images/mobile/paper_partner.png',
    thumbnail: '/images/mobile/paperworkshop_partners/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/mobile/paperworkshop_partners/screen_capture01.png',
      '/images/mobile/paperworkshop_partners/screen_capture02.png',
      '/images/mobile/paperworkshop_partners/screen_capture03.png',
    ],
    role: 'Front-End Development',
    service: ['Mobile', 'React Native', 'Front-end Development'],
    os: 'iOS, Android',
    content: `페이퍼 공작소 입점 업체 전용 모바일 어플리케이션.
    페이퍼 공작소 사용자들의 주문 현황을 볼 수 있고 입찰을 하고 주문자와 컨텍 및 채팅 등을 할 수 있는 기능을 가진 어플리케이션.
    입찰 가격 지정 및 주문자 주문 상세 내역의 인쇄 관련 업체 측 제안 기능이 있으며 거래 진행 시 현재 진행상황별 사용자와의 양방향 진행 버튼 및 알림 기능으로 구현된 어플리케이션입니다.     
      `,
    sourcePath: 'https://github.com/Louis-jk/paperworkshop_partners',
    issue: ``,
    functions: [
      '로그인 기능',
      '프로필 관리 기능',
      '실시간 주문 알림 기능',
      '실시간 주문 리스트 열람 기능',
      '주문 접수 및 주문 현황 등록 및 수정 기능',
      '주문자 선 연락시 채팅 기능',
      '파일 업로드 및 사진 업로드 기능',
    ],
    isMobile: true,
    isExistVideo: false,
    device: 'samsungs9bar',
    client: '(유)프로피앤피',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'detail',
        source: [
          {
            type: 'image',
            path: '/images/mobile/paperworkshop_partners/detail/detail01.png',
          },
        ],
      },
    ],
  },
  {
    id: 'paperworkshop_customer',
    nextProjectId: 'insadong',
    nextProjectName: 'Insadong\ndisappeared',
    title: '페이퍼공작소-사용자',
    title_en: 'paper\nworkshop\nFor Customers',
    title_short: 'paper\nworkshop\nFor Customers',
    links: [
      {
        type: 'android',
        url: 'https://play.google.com/store/apps/details?id=com.dmonster.paperworkshop',
      },
    ],
    development: 'mobile',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2021.02 - 2022.06',
    skills: [
      'React Native',
      'Redux',
      'Axios',
      'FCM(Firebase Cloud Messaging)',
      'react-native-image-crop-picker',
      'formik',
      'yup',
      'react-native-fs',
    ],
    assets: '/images/mobile/paper_client.png',
    thumbnail: '/images/mobile/paperworkshop_customer/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/mobile/paperworkshop_customer/screen_capture01.png',
      '/images/mobile/paperworkshop_customer/screen_capture02.png',
      '/images/mobile/paperworkshop_customer/screen_capture03.png',
    ],
    role: 'Front-End Development',
    service: ['Mobile', 'React Native', 'Front-end Development'],
    os: 'iOS, Android',
    content: `페이퍼 공작소 의뢰자용 모바일 어플리케이션.
    페이퍼 공작소 입점 업체의 리스트를 볼 수 있고 카테고리별 필터링 기능 및 주문 후 입찰 업체의 역제안서를 확인해 볼 수 있으며 주문 결정 시 해당 업체와 컨텍 및 채팅 등을 할 수 있는 기능을 가진 어플리케이션.
    메인 관리자의 인쇄 관련 정보 및 주문 단계별 해당 인쇄 카테고리의 세부 사항 등의 정보를 별도 모달창으로 안내받을 수 있으며 인쇄 주문까지 총 6단계의 단계가 있습니다.
    인쇄 특성상 카테고리에 따른 지류 선택 및 지류에 따른 용지별 무게, 색상 등 유동적인 선택이 많은 프로젝트로 기억에 남아 있습니다.
      `,
    sourcePath: 'https://github.com/Louis-jk/paperworkshop',
    issue: ``,
    functions: [
      '일반 로그인 & SNS 로그인 기능',
      '프로필 관리 기능',
      '인쇄 업체 리스트 확인 기능',
      '인쇄 업체별 리스트 필터링 기능',
      '주문 의뢰 기능',
      '주문 시 업체 유선 전화 또는 채팅 기능',
      '파일 업로드 및 사진 업로드 기능',
    ],
    isMobile: true,
    isExistVideo: false,
    device: 'samsungs9bar',
    client: '(유)프로피앤피',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'detail',
        source: [
          {
            type: 'image',
            path: '/images/mobile/paperworkshop_customer/detail/detail01.png',
          },
        ],
      },
    ],
  },
  {
    id: 'insadong',
    nextProjectId: 'gongjuro',
    nextProjectName: 'Gongjuro',
    title: '사라진 인사동',
    title_en: 'Insadong\ndisappeared',
    title_short: 'Insadong',
    links: [
      {
        type: 'android',
        url: 'https://play.google.com/store/apps/details?id=com.dmonster.insadong',
      },
    ],
    development: 'mobile',
    project: '기업',
    description: '인사동을 소개하는 오디오북 앱',
    date: '2020.12 - 2021.01',
    skills: [
      'React Native',
      'Redux',
      'PHP',
      'MariaDB',
      'Axios',
      'react-native-track-player',
    ],
    assets: '/images/mobile/insadong.png',
    thumbnail: '/images/mobile/insadong/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/mobile/insadong/screen_capture01.png',
      '/images/mobile/insadong/screen_capture02.png',
      '/images/mobile/insadong/screen_capture03.png',
    ],
    role: 'Full service Development',
    service: ['Mobile', 'React Native & PHP', 'Full Stack Development'],
    os: 'Android',
    content: `서울 인사동의 역사를 안내하는 오디오북 모바일 어플리케이션.
    인사동의 역사를 이미지와 음성으로 보고 들을 수 있으며 현재 자신의 위치와 해당 인사동 위치와의 거리도 안내받을 수 있는 기능을 가지고 있습니다.
    풀스택으로 진행하였으며 BackEnd는 PHP, FrontEnd는 React Native로 제작하였습니다.
      `,
    sourcePath: 'https://github.com/Louis-jk/insadong',
    issue: ``,
    functions: [
      '관리자, 사용자 - 로그인 기능',
      '관리자 - 이미지 업로드 기능',
      '관리자 - 오디오 업로드 기능',
      '사용자 - 리스트 확인 및 오디오 청취 기능',
      '그 외 기능',
    ],
    isMobile: true,
    isExistVideo: false,
    device: 'samsungs9bar',
    client: '브이알북',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'detail',
        source: [
          {
            type: 'image',
            path: '/images/mobile/insadong/detail/detail01.png',
          },
        ],
      },
    ],
  },
  {
    id: 'gongjuro',
    nextProjectId: 'movieapp',
    nextProjectName: 'Movie App',
    title: '공주로',
    title_en: 'Gongjuro',
    title_short: 'Gongjuro',
    links: [
      {
        type: 'android',
        url: 'https://play.google.com/store/apps/details?id=com.dmonster.dmonster1427',
      },
      {
        type: 'ios',
        url: 'https://apps.apple.com/ko/app/%EA%B3%B5%EC%A3%BC%EB%A1%9C/id1544712725',
      },
      {
        type: 'web',
        url: 'https://gongju-ro.com',
      },
    ],
    development: 'mobile',
    project: '기업',
    description: '전국 맛집 소개하는 웹/앱 어플리케이션',
    date: '2020.10 - 2021.01',
    skills: [
      'React Native',
      'Redux',
      'Axios',
      'FCM(Firebase Cloud Messaging)',
      'formik',
      'yup',
      'react-native-image-crop-picker',
    ],
    assets: '/images/mobile/gongjooro.png',
    thumbnail: '/images/mobile/gongjuro/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: [
      '/images/mobile/gongjuro/screen_capture01.png',
      '/images/mobile/gongjuro/screen_capture02.png',
      '/images/mobile/gongjuro/screen_capture03.png',
    ],
    role: 'Front-End Development',
    service: ['Mobile', 'React Native', 'Front-end Development'],
    os: 'iOS, Android',
    content: `대한민국 공주시 홍보 모바일 어플리케이션.
    관리자 홍보용 로컬큐레이션과 관리자 홍보 및 사용자 커뮤니티에 등록된 공주시 맛집, 쇼핑 등 여행 정보 검색 기능, 공주시의 추천 명소 등을 볼 수 있는 지도페이지, 사용자들의 커뮤니티 페이지로 이루어진 어플리케이션입니다.
      `,
    sourcePath: 'https://github.com/Louis-jk/gongju-ro',
    issue: ``,
    functions: [
      '일반 로그인 및 SNS 로그인 기능',
      '로컬 큐레이션 리스트 출력 기능',
      '로컬 톡톡 커뮤니티 기능',
      '로컬 트립 지도 출력 및 해당 관광페이지 확인 기능',
      '관광 페이지 유선 전화 연결 및 연락 기능',
      '프로필 관리 기능',
      '이미지 업로드 기능',
    ],
    isMobile: true,
    isExistVideo: false,
    device: 'iphone13',
    client: '(주)트래블공주',
    company: 'Dmonster',
    location: '한국 부산',
    detail: [
      {
        type: 'detail',
        source: [
          {
            type: 'image',
            path: '/images/mobile/gongjuro/detail/detail01.png',
          },
        ],
      },
    ],
  },
  {
    id: 'movieapp',
    nextProjectId: 'dongnaebook_pos',
    nextProjectName: 'Dongnae\nbook\npos system',
    title: '무비앱 - 개인 프로젝트',
    title_en: 'Movie App',
    title_short: 'Movie App',
    links: [
      {
        type: 'web',
        url: 'https://5eda602e40a67a000791f9dc--modest-dijkstra-b9b7c0.netlify.app/',
      },
    ],
    development: 'mobile',
    project: '개인',
    description: '영화 정보 안내 사이트',
    date: '2020.05 - 2020.06',
    skills: ['React', 'Redux', 'Axios'],
    assets: '/images/web/themovie.png',
    thumbnail: '/images/web/movieapp/thumbnail02.png',
    videos: [
      '/videos/dongnaebook_owner/screen_record.mp4',
      '/videos/dongnaebook_owner/Recording_2022-07-26-13-37-47.mp4',
      '/videos/dongnaebook_owner/review.mp4',
    ],
    images: ['/images/web/movieapp/main_screen.png'],
    role: 'Front-End Development',
    service: ['Web', 'React', 'Front-end Development'],
    os: 'Web Browser',
    content: `리액트(React.js)로 만든 개인용 영화 정보 사이트.
    인기영화, 현재 상영영화, 개봉 예정영화 로 카테고리별 영화 리스트를 볼 수 있으며 해당 영화별 상세 내용 및 추천 영화, 비슷한 장르 영화가 포함되어 있고 영화 검색 기능과 영화 찜하기 기능, 최근 본 영화 등 Redux를 이용하여 프론트단에서만으로 제한적이지만 상태관리를 할 수 있는 어플리케이션입니다. 
      `,
    sourcePath: 'https://github.com/Louis-jk/MovieApp',
    issue: ``,
    functions: [
      '인기영화 리스트 페이지',
      '현재 상영영화 리스트 페이지',
      '개봉 예정영화 리스트 페이지',
      '영화 검색 기능',
      '찜하기 기능',
      '찜한 영화 리스트 페이지',
      '최근 본 영화 리스트 페이지',
    ],
    isMobile: false,
    isExistVideo: false,
    device: 'iphone14',
    client: '',
    company: '',
    location: '한국 부산',
    detail: [
      {
        type: 'mobile',
        source: [
          {
            type: 'image',
            path: '/images/web/movieapp/detail/detail01.png',
          },
          {
            type: 'video',
            path: '/videos/movieapp/detail/detail_mobile01.mp4',
          },
          {
            type: 'image',
            path: '/images/web/movieapp/detail/detail02.png',
          },
        ],
      },
    ],
  },
]
